<template>
  <div>
    <p v-html="page.inleiding"></p>
  
    <v-form ref="form" v-model="formValid">
      <div v-for="(v, index) in formModel" :key="index">

        <v-layout pa-2 class="question" mt-5 wrap v-if="v.soort === 'text'">
      
          <v-flex xs12>{{ v.omschrijving }}</v-flex>
          <v-flex xs12>
            <v-text-field  
              :rules="v.vp ? rules : []" 
              v-model="v.reactie" 
              :required="v.vp">
            </v-text-field>
          </v-flex>
        </v-layout>

        <v-layout pa-2 class="question" mt-5 wrap v-if="v.soort === 'textnum'">
      
      <v-flex xs12>{{ v.omschrijving }}</v-flex>
      <v-flex xs12>
        <v-text-field  
          :rules="v.vp ? rules : []" 
          v-model="v.reactie" 
          :required="v.vp"
          type="number">
        </v-text-field>
      </v-flex>
    </v-layout>

        <v-layout pa-2 class="question" mt-5 wrap v-if="v.soort === 'textarea'">
          <v-flex xs12>{{ v.omschrijving }}</v-flex>
          <v-flex xs12>
            <v-textarea 
              :rules="v.vp ? rules : []"
              v-model="v.reactie" 
              filled 
              :required="v.vp">
            </v-textarea>
          </v-flex>
        </v-layout>

        <!--
        <v-layout pa-2 class="question" mt-5 wrap v-if="v.soort === 'select'">
          <v-flex xs12 sm8>{{ v.omschrijving }}</v-flex>
          <v-flex xs12 pl-3 sm4>
            <v-select
              v-model="v.reactie"
              :items="enums.filter((f) => f.soort === v.items)"
              :rules="v.vp ? rules : []"
              :required="v.vp"
            ></v-select>
          </v-flex>
        </v-layout>-->
        
        <selection-list :vraag="v" @answerChanged="updateReactie($event,v)" v-if="v.soort === 'list'" :value="v.reactie" :enums="enums.filter((f) => f.soort === v.items)"></selection-list>
      
        <opleiding-list :vraag="v" @answerChanged="updateReactie($event,v)" v-if="v.soort === 'opleiding'" :value="v.reactie"></opleiding-list>
     
        <select-text :enums="enums.filter((f) => f.soort === v.items)" v-if="v.soort === 'select'" :rules="v.vp ? rules : []" :vraag="v" ></select-text>

      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import selectionList from "./SelectionList";
import opleidingList from "./OpleidingList";
import selectText from './SelectText'

export default {
  name: "VragenForm",
  props: ["page"],
  components: {
    selectionList,
    opleidingList,
    selectText
  },
  data: () => ({
    formValid: false,
    rules: [ value => !!value || 'Waarde is verplicht'],
    formModel: [],
    v: null,
  }),
  methods: {
    ...mapActions(["updateAntwoorden"]),

    updateReactie(e,v){
      const found = this.formModel.find(f=>f.id===v.id);

      if (found !=null){
        found.reactie = e;
      }

    },

    submitPrev() {
        this.updateAntwoorden(this.formModel);
    },

    submitNext(makeEmpty) {
      if (this.formValid) {
    
        this.updateAntwoorden(this.formModel);
        if(makeEmpty)
          this.formModel = [];
      
      }
    },

    
  },
  computed: {
    ...mapGetters({
      vragenlijst: "vragenlijst",
    }),

    ...mapState({
      enums: "enums",
    }),
  },
  watch: {
    formValid: function (val) {
      this.$emit("pageValidChanged", val);
    },

    page: {
      immediate: true,
      handler(nv) {
        if (nv !== null) {
          this.formModel = [
            // gebruik map om een kopie te maken van de array
            ...this.vragenlijst
              .filter((f) => f.pagina_id === nv.id)
              .map((m) => ({ ...m })),
          ];
        }
      },
    },
  },
};
</script>

<style scoped>
.question {
  background-color: #f0f0f0;
}

p {
  color: black;
}

</style>